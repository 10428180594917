@import '@hh.ru/magritte-ui/tokens';
@import '/src/styles/font-title-secondary';

.title-secondary_size-1 {
    .font-title-secondary-1();
}

.title-secondary_size-2 {
    .font-title-secondary-2();
}

.title-secondary_size-3 {
    .font-title-secondary-3();
}

.title-secondary_size-4 {
    .font-title-secondary-4();
}

.title-secondary_size-5 {
    .font-title-secondary-5();
}

.title-secondary_style-primary {
    color: @magritte-color-text-primary;
}

.title-secondary_style-contrast {
    color: @magritte-color-text-contrast;
}

.title-secondary_alignment-left {
    text-align: left;
}

.title-secondary_alignment-center {
    text-align: center;
}
